import React from 'react'
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { lightBlue } from '@material-ui/core/colors'
import * as R from 'ramda'
import { PrivacyPolicyConfirmation } from './PrivacyPolicyConfirmation'
import { PrivacyPolicyConfirmationMobile } from './PrivacyPolicyConfirmationMobile'

const applyCustomChatFrame = (krkrChat: HTMLElement, isPpm: boolean) => {
  const style = document.createElement('style')
  style.textContent =
    '.krkr-chat-custom { width: 375px; } @media (max-width:600px), (max-height: 560px) { .krkr-chat-custom { width: auto; }}'
  document.body.appendChild(style)
  krkrChat.classList.add('krkr-chat-custom')
  const closeIcon = krkrChat.querySelector('div>svg') as SVGElement
  const header = closeIcon.parentElement as HTMLElement
  header.style.backgroundColor = '#242524'
  header.style.color = 'white'
  header.style.position = 'relative'
  header.style.justifyContent = 'center'
  header.style.paddingTop = '12px'
  header.style.paddingBottom = '12px'
  header.style.fontSize = 'larger'
  header.style.fontWeight = 'bold'
  header.innerHTML = `
  <span>教えて！箱猫${isPpm ? 'タカシちゃん' : 'マックスくん'}</span>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white" style="position:absolute; right:10px;">
    <path fill="none" d="M0 0h24v24H0z"/>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 11h10v2H7z"/>
  </svg>
  `
}

export class PrivacyPolicyConfirmationState {
  private karakuriPrivacyPolicyConfirmationState = ''
  private karakuriPrivacyPolicyConfirmationDisplay = ''

  constructor(readonly privacyPolicyUrl: string) {}

  private setValue(value: string) {
    try {
      sessionStorage.karakuriPrivacyPolicyConfirmationState = value
    } catch {
      this.karakuriPrivacyPolicyConfirmationState = value
    }
  }

  private get value() {
    try {
      return sessionStorage.karakuriPrivacyPolicyConfirmationState
    } catch {
      return this.karakuriPrivacyPolicyConfirmationState
    }
  }

  get isConfirmed() {
    return this.value && this.value === 'confirmed'
  }

  get isConfirming() {
    const url = this.privacyPolicyUrl.startsWith('http')
      ? this.privacyPolicyUrl
      : `${new URL(this.privacyPolicyUrl, window.location.origin).href}`
    return this.value && this.value === 'confirming' && window.location.href.startsWith(url)
  }

  get initialDisplay() {
    try {
      const value = sessionStorage.getItem('karakuriPrivacyPolicyConfirmationDisplay')
      sessionStorage.removeItem('karakuriPrivacyPolicyConfirmationDisplay')
      return !!value
    } catch {
      const value = this.karakuriPrivacyPolicyConfirmationDisplay
      this.karakuriPrivacyPolicyConfirmationDisplay = ''
      return !!value
    }
  }

  setInitialDisplay() {
    try {
      sessionStorage.setItem('karakuriPrivacyPolicyConfirmationDisplay', 'true')
    } catch {
      this.karakuriPrivacyPolicyConfirmationDisplay = 'true'
    }
  }

  confirm() {
    this.setValue('confirmed')
  }

  confirming() {
    this.setValue('confirming')
  }

  reset() {
    this.setValue('')
  }
}

const App: React.FC<{
  popupIconUrl?: string
  privacyPolicyConfirmationState: PrivacyPolicyConfirmationState
  isPpm: boolean
  isMobile: boolean
}> = props => {
  const fontColor = props.isMobile ? '#333333' : '#2D2D2D'
  const theme = createMuiTheme({
    palette: {
      primary: {
        ...lightBlue,
        main: '#36B7F4',
        dark: '#36B7F4',
      },
    },
    typography: {
      fontFamily:
        '"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Lucida Grande", Verdana, "ＭＳ Ｐゴシック", sans-serif',
      subtitle2: {
        fontSize: '13px',
        lineHeight: '22px',
        fontWeight: 300,
        color: fontColor,
      },
      h5: {
        fontSize: '19px',
        lineHeight: '32.5px',
        fontWeight: 300,
        color: fontColor,
      },
      body1: {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 300,
      },
      body2: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 300,
      },
    },
  })
  const { popupIconUrl, privacyPolicyConfirmationState, isPpm, isMobile } = props

  const [open, setOpen] = React.useState(privacyPolicyConfirmationState.initialDisplay)
  const [checked, setChecked] = React.useState(false)

  const onConfirmCheckChanged = (x: boolean) => {
    setChecked(x)
  }

  const onClose = () => {
    setOpen(false)
    const karakuriButton = document.querySelector('div[class^="krkr-button"]') as HTMLElement
    karakuriButton.style.display = 'flex'
  }

  const onChatStartClick = () => {
    privacyPolicyConfirmationState.confirm()
    const karakuriButton = document.querySelector('div[class^="krkr-button"]') as HTMLElement
    karakuriButton.click()
    setOpen(false)
  }

  const [alertOpen, setAlertOpen] = React.useState(false)
  const [outwardLinkUrl, setOutwardLinkUrl] = React.useState<string>()

  React.useEffect(() => {
    // 標準ポップアップボタンの挙動をカスタマイズ
    const popupJs = document.querySelector('script[src*="/api/chats/popup.js"]') as HTMLElement
    const karakuriButton = document.querySelector('div[class^="krkr-button"]') as HTMLElement
    if (popupJs && karakuriButton) {
      if (!popupJs.dataset.suggestCount) {
        popupJs.dataset.suggestCount = '5'
      }
      const isLoggedIn = !!popupJs.dataset.customerMemberId
      if (!isLoggedIn) {
        const outer = document.createElement('div')
        outer.appendChild(karakuriButton)
        outer.addEventListener(
          'click',
          e => {
            if (privacyPolicyConfirmationState.isConfirming) {
              e.preventDefault()
              e.stopPropagation()
              privacyPolicyConfirmationState.reset()
              privacyPolicyConfirmationState.setInitialDisplay()
              window.history.back()
            } else if (!privacyPolicyConfirmationState.isConfirmed) {
              e.preventDefault()
              e.stopPropagation()
              karakuriButton.style.display = 'none'
              setOpen(true)
            }
          },
          true
        )
        document.body.appendChild(outer)
      }

      const observer = new MutationObserver(mutations => {
        const chatFrame = R.flatten(mutations.map(x => Array.from(x.addedNodes)))
          .map(x => x as HTMLElement)
          .find(x => x.className === 'krkr-chat')
        if (chatFrame) {
          applyCustomChatFrame(chatFrame, isPpm)
        }
      })
      observer.observe(document.body, { childList: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    // プライバシーポリシーページのaタグによる遷移を抑制
    if (!privacyPolicyConfirmationState.isConfirming) {
      return
    }
    const aTags = Array.from(document.getElementsByTagName('a'))
    aTags.forEach(a => {
      a.setAttribute('_href', a.href)
      // eslint-disable-next-line no-script-url
      a.href = 'javascript:void(0)'
      a.addEventListener('click', e => {
        setOutwardLinkUrl((e.target as HTMLAnchorElement).getAttribute('_href') as string)
        setAlertOpen(true)
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    // ボットアイコンを変更
    const karakuriButtonImage = document.querySelector('.krkr-button-img>img') as HTMLElement
    if (!karakuriButtonImage) {
      return
    }
    if (popupIconUrl) {
      karakuriButtonImage.removeAttribute('srcset')
      karakuriButtonImage.style.maxWidth = '102px'
      karakuriButtonImage.style.maxHeight = '102px'
      karakuriButtonImage.setAttribute('src', popupIconUrl)
      return
    }
    if (privacyPolicyConfirmationState.isConfirming) {
      karakuriButtonImage.removeAttribute('srcset')
      karakuriButtonImage.style.maxWidth = '102px'
      karakuriButtonImage.style.maxHeight = '102px'
      karakuriButtonImage.setAttribute(
        'src',
        'https://s3.karakuri.ai/images/zozo-cs/policy_banner.png'
      )
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      {isMobile && (
        <PrivacyPolicyConfirmationMobile
          open={open}
          checked={checked}
          onConfirmCheckChanged={onConfirmCheckChanged}
          onChatStartClick={onChatStartClick}
          onClose={onClose}
          avatarImageUrl={
            isPpm
              ? 'https://s3.karakuri.ai/images/zozo-cs/policy_takashi.png'
              : 'https://s3.karakuri.ai/images/zozo-cs/policy_max.png'
          }
          privacyPolicyUrl={privacyPolicyConfirmationState.privacyPolicyUrl}
          isPpm={isPpm}
          fontColor={fontColor}
        />
      )}
      {!isMobile && (
        <PrivacyPolicyConfirmation
          open={open}
          checked={checked}
          onConfirmCheckChanged={onConfirmCheckChanged}
          onChatStartClick={onChatStartClick}
          onClose={onClose}
          avatarImageUrl={
            isPpm
              ? 'https://s3.karakuri.ai/images/zozo-cs/policy_takashi.png'
              : 'https://s3.karakuri.ai/images/zozo-cs/policy_max.png'
          }
          privacyPolicyUrl={privacyPolicyConfirmationState.privacyPolicyUrl}
          isPpm={isPpm}
          fontColor={fontColor}
        />
      )}
      <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
        <DialogContent>
          <DialogContentText>
            チャットボットのページから離脱してしまいますが、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setAlertOpen(false)
              privacyPolicyConfirmationState.reset()
              window.location.href = outwardLinkUrl
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  )
}

export default App
