import React from 'react'
import {
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormControlLabel,
  Container,
  Fab,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'visible',
    textAlign: 'center',
    paddingTop: 40.5,
    paddingBottom: 40.5,
    paddingLeft: 16,
    paddingRight: 16,
  },
  closeButton: {
    position: 'absolute',
    right: -12,
    top: -12,
    height: 26,
    minHeight: 26,
    width: 26,
    boxShadow: 'none',
  },
  avatar: {
    maxHeight: 141,
    maxWidth: 141,
    marginBottom: 12,
  },
  title: {
    marginBottom: 4,
  },
  caption: {
    fontSize: '12px',
    lineHeight: '20.5px',
    fontWeight: 300,
    color: (props: { fontColor: string }) => props.fontColor,
  },
  actions: {
    padding: 0,
    flexDirection: 'column',
  },
  actionList: {
    padding: 0,
  },
  actionListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  link: {
    color: '#0088CC',
  },
  chatStartButton: {
    width: 313,
    height: 51,
    marginTop: 6,
    color: 'white',
    boxShadow: 'none',
    // '&:hover': {
    //   boxShadow: 'none',
    // },
  },
  chatStartButtonDisabled: {
    width: 313,
    height: 51,
    marginTop: 6,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
    opacity: 0.5,
  },
  privacyPolicyFrame: {
    maxWidth: '100%',
    marginTop: 12,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
  },
}))

interface PrivacyPolicyConfirmationProps {
  checked: boolean
  onConfirmCheckChanged: (checked: boolean) => void
  onChatStartClick: React.MouseEventHandler<HTMLElement>
  open: boolean
  onClose: React.MouseEventHandler<HTMLElement>
  avatarImageUrl: string
  privacyPolicyUrl: string
  isPpm: boolean
  fontColor: string
}

export const PrivacyPolicyConfirmation: React.FC<PrivacyPolicyConfirmationProps> = props => {
  const {
    checked,
    onConfirmCheckChanged,
    onChatStartClick,
    open,
    onClose,
    avatarImageUrl,
    privacyPolicyUrl,
    isPpm,
  } = props
  const classes = useStyles(props)
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ className: classes.root }}>
      <Fab className={classes.closeButton} size="small" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </Fab>
      <Container>
        <img src={avatarImageUrl} className={classes.avatar} alt="箱猫マックスくん" />
        <Typography variant="subtitle2">チャットボットサービス</Typography>
        <Typography variant="h5" className={classes.title}>{`教えて！箱猫${
          isPpm ? 'タカシちゃん' : 'マックスくん'
        }`}</Typography>
      </Container>
      <DialogContent>
        <Typography className={classes.caption}>チャットボットをご利用するには、</Typography>
        <Typography className={classes.caption}>プライバシーポリシーの同意が必要です。</Typography>
        <Typography className={classes.caption}>以下の規約をご確認の上、お進み下さい。</Typography>
        <iframe
          title="プライバシーポリシー"
          src={privacyPolicyUrl}
          className={classes.privacyPolicyFrame}
          frameBorder={0}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={x => onConfirmCheckChanged(x.target.checked)}
                color="primary"
                icon={<CheckIcon color="disabled" />}
                checkedIcon={<CheckIcon />}
              />
            }
            label={<Typography variant="body2">プライバシーポリシーに同意する</Typography>}
          />
        </div>
        <div style={{ marginLeft: 0 }}>
          <Button
            className={checked ? classes.chatStartButton : classes.chatStartButtonDisabled}
            variant="contained"
            fullWidth
            size="large"
            onClick={onChatStartClick}
            color="primary"
            disabled={!checked}
          >
            <Typography>チャットをはじめる</Typography>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}
